import React from 'react'
import { css } from 'emotion'
import { CFImage, CFView, PrimaryModal } from 'components'
import { doorDash, skipTheDishes, uberEats } from 'images'

export default props => {
  return (
    <PrimaryModal
      title={'Choose Delivery Provider'}
      isOpen={props.showModal}
      onRequestClose={props.onRequestClose}
    >
      <CFView className={styles.container}>
        <CFView column alignCenter w="100%">
          <a
            href={`https://www.doordash.com/store/sushi-wara-delta-2489263/?utm_campaign=gpa`}
          >
            <CFView hover>
              <CFImage maxWidth="290px" src={doorDash} alt="Skip the Dishes" />
            </CFView>
          </a>
          <a href={`https://www.skipthedishes.com/sushi-wara-120th-street`}>
            <CFView hover mt="15px">
              <CFImage maxWidth="290px" src={skipTheDishes} alt="Door Dash" />
            </CFView>
          </a>
          <a
            href={`https://www.ubereats.com/ca/store/sushi-wara/bGzfDrRHTpGCZLkGI8CULA?pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMjEwMzA5JTIwTm8uJTIwMyUyMFJkJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyRWl3eE1ETXdPU0JPYnk0Z015QlNaQ3dnVW1samFHMXZibVFzSUVKRElGWTNRU0F5UlRRc0lFTmhibUZrWVNKUkVrOEtOQW95Q2YxUElTc2c0SVZVRVlKc0JURFBuVDhHR2g0TEVPN0I3cUVCR2hRS0VnbXh2aXg3SC1DRlZCSHI2TjZHdVVUdzRBd1F4VkFxRkFvU0NRWGFPc0JMNElWVUVjdlV3LU1MLTM1WSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E0OS4xMzg2OTY3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTEyMy4xMzczNDklN0Q%3D`}
          >
            <CFView hover mt="15px">
              <CFImage maxWidth="290px" src={uberEats} alt="UberEats" />
            </CFView>
          </a>
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}

const styles = {
  container: css({
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '400px',
    '@media (max-width: 767px)': {
      padding: '30px',
      width: '330px',
    },
  }),
}
