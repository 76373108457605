import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import {
  foodGallery,
  mobileGallery,
  gallery1,
  gallery2,
  gallery3,
} from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileGallery} w="75%" alt="Food Gallery" mt="25px" />
        </CFView>
        <Slider {...{ ...settings, slidesToShow: 1 }}>
          <CFImage
            src={gallery1}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pb="20px"
          />
          <CFImage
            src={gallery2}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pb="20px"
          />
          <CFImage
            src={gallery3}
            style={{ objectFit: 'contain' }}
            ph="15px"
            pb="20px"
          />
        </Slider>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" m="0 auto" w="100%" mt="-1px">
          <CFImage src={foodGallery} alt="Food Gallery" w="100%" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
