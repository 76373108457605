import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#D40F33" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            {/*<CFLink target="_blank" href="https://www.facebook.com/CANADASUSHI/">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Wara Delta Facebook"
              />
            </CFLink>*/}
            <CFLink target="_blank" href="https://www.zomato.com/vancouver/sushi-wara-north-delta/menu">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Wara Delta Zomato"
              />
            </CFLink>
            <CFLink target="_blank" href="https://www.yelp.ca/biz/sushi-wara-delta">
              <CFImage
                w="40px"
                pr="10px"
                src={yelp}
                alt="Wara Delta Yelp"
              />
            </CFLink>
            <CFLink target="_blank" href="https://www.instagram.com/sushiwaradelta/?hl=en">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Wara Delta Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#D40F33" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            {/*<CFLink target="_blank" href="https://www.facebook.com/CANADASUSHI/">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Wara Delta Facebook"
              />
            </CFLink>*/}
            <CFLink target="_blank" href="https://www.zomato.com/vancouver/sushi-wara-north-delta/menu">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Wara Delta Zomato"
              />
            </CFLink>
            <CFLink target="_blank" href="https://www.yelp.ca/biz/sushi-wara-delta">
              <CFImage
                w="40px"
                pr="10px"
                src={yelp}
                alt="Wara Delta Yelp"
              />
            </CFLink>
            <CFLink target="_blank" href="https://www.instagram.com/sushiwaradelta/?hl=en">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Wara Delta Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
